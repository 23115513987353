import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map, List, fromJS } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";

import { loadEnvironments } from "Reducers/environment";

import EnvironmentTree from "Components/EnvironmentTree";

const getTreeData = (nodes, environments, depth = 0) => {
  return nodes.map(node => {
    const newNodes = environments
      .filter(environment => environment.parent === node.id)
      .valueSeq()
      .toJS();

    let children = [];

    if (newNodes.length) {
      children = getTreeData(newNodes, environments, depth + 1);
    }

    return {
      environmentId: node.id,
      projectId: node.project,
      organizationId: node.organization,
      created_at: node.created_at,
      updated_at: node.updated_at,
      title: node.title,
      isRoot: !node.parent,
      type: node.type,
      isDirty: node.status === "dirty",
      expanded: true,
      status: node.status,
      isMock: node.isMock,
      deployment_target: node.deployment_target,
      depth,
      children
    };
  });
};

// TODO: Refactor the Navbar container to be able to move that in the project app
class EnvironmentsTree extends React.Component {
  // eslint-disable-line
  constructor(props) {
    super(props);

    this.state = {
      treeData: new List()
    };
  }

  componentDidMount() {
    if (this.props.organizationId) {
      this.props.loadEnvironments();
    }

    if (this.props.environments) {
      const rootEnvironments = this.getRootEnvironments(
        this.props.environments
      );
      const data = getTreeData(rootEnvironments, this.props.environments);

      this.setState({
        treeData: fromJS(data)
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.projectId !== this.props.projectId &&
      this.props.organizationId
    ) {
      this.props.loadEnvironments();
    }

    if (this.props.environments !== nextProps.environments) {
      const rootEnvironments = this.getRootEnvironments(nextProps.environments);
      const data = getTreeData(rootEnvironments, nextProps.environments);

      this.setState({
        treeData: fromJS(data)
      });
    }
  }

  getRootEnvironments(environments) {
    const rootEnvironments = environments.filter(
      environment => !environment.parent
    );

    const environmentKeys = environments.map(env => env.id).toArray();
    const shouldMockRootEnvironments = rootEnvironments.toArray().length === 0;

    if (shouldMockRootEnvironments) {
      const mockKeys = [];
      const mockRootEnvironments = environments.reduce((mocks, value) => {
        const hasParentAvailable = environmentKeys.indexOf(value.parent) > -1;
        const hasMockCreated = mockKeys.indexOf(value.parent) > -1;

        if (hasParentAvailable || hasMockCreated) {
          return mocks;
        }

        const mock = {
          id: value.parent,
          environmentId: null,
          projectId: null,
          organizationId: null,
          updated_at: null,
          title: value.parent.charAt(0).toUpperCase() + value.parent.slice(1),
          isRoot: true,
          isDirty: false,
          expanded: true,
          status: null,
          deployment_target: "local",
          isMock: true
        };
        mockKeys.push(value.parent);
        return mocks.push(mock);
      }, new List());
      return mockRootEnvironments.valueSeq().toJS();
    }

    return rootEnvironments.valueSeq().toJS();
  }

  render() {
    const layout = this.props.layout ? this.props.layout : "";

    return (
      <div>
        <EnvironmentTree
          data={this.state.treeData}
          layout={layout}
          organizationId={this.props.organizationId}
          projectId={this.props.projectId}
          environmentId={this.props.environmentId}
          closeDropDown={this.props.closeDropDown}
        />
      </div>
    );
  }
}

EnvironmentsTree.propTypes = {
  environments: ImmutablePropTypes.map,
  loadEnvironments: PropTypes.func,
  environmentId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  layout: PropTypes.string,
  closeDropDown: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const environment = state.environment || new Map();

  return {
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    isLoading: environment.get("loading")
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironments: () =>
    dispatch(loadEnvironments(props.projectId, props.organizationId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentsTree);
