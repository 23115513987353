import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import withReducers from "Hocs/withReducers";
import { hasSafeRole } from "Libs/utils";
import { getMe } from "Reducers/app";

import { ModalTrial } from "Components/";
import ButtonLink from "Components/ButtonLink";

import { getPaymentSource } from "Reducers/paymentSource";

const NewProjectButton = ({ linkType, organizationId, user }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [estimate, setEstimate] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef();

  const loadingPaymentSource = useSelector(state =>
    state.paymentSource?.get("loading")
  );
  const hasPaymentSource = useSelector(state => {
    const ps = state.paymentSource?.get("data");
    return !!ps?.type;
  });

  const getEstimation = async () => {
    if (estimate !== undefined) return;
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    await client
      .getSubscriptionEstimate(
        "trial/development",
        "1024",
        "3",
        "1",
        undefined,
        "complex"
      )
      .then(estimation => setEstimate(estimation));

    if (user.data?.current_trial === undefined) dispatch(getMe(true));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);

    return () =>
      document.removeEventListener("click", handleClickOutside, false);
  }, []);

  useEffect(
    () => {
      if (user && !hasSafeRole(user.roles)) {
        if (user.trial || !user.data?.current_trial) getEstimation();
        if (!loadingPaymentSource) dispatch(getPaymentSource());
      }
    },
    [user?.id]
  );

  const handleClickOutside = e => {
    if (!ref.current?.contains(e.target)) {
      toggleModal(false);
    }
  };

  const openModal = e => {
    e?.preventDefault();
    toggleModal(true);
  };

  const checkRights = () => {
    if (process.env.ENABLE_ORGANIZATION) {
      return [true, null];
    }

    const { data, trial } = user;

    if (hasSafeRole(user.roles)) return [true, null];

    if (!trial) {
      if (hasPaymentSource) return [true, null];
      if (hasPaymentSource === false) return [false, "very_hard"];
    } else {
      const { current_trial } = data;
      if (current_trial === null && !hasPaymentSource) return [false, "hard"];

      if (current_trial?.active && estimate?.trial?.allowed)
        return [true, null];

      if (
        current_trial?.active &&
        moment(current_trial?.expiration).isAfter(moment()) &&
        !estimate?.trial?.allowed
      )
        return [false, "soft"];

      if (
        current_trial?.active &&
        moment(current_trial?.expiration).isSameOrBefore(moment()) &&
        !estimate?.trial?.allowed
      )
        return [false, "hard"];

      if (!current_trial?.active) return [false, "hard"];
    }
    return [null, null];
  };

  if (!user) return false;

  const [canCreate, kindModal] = checkRights();
  if (canCreate === null && kindModal === null) return false;

  let external = false;
  let buttonUrl = `/${
    organizationId ? organizationId : "projects"
  }/create-project`;

  if (process.env.CUSTOM_PROJECT_CREATION_URL) {
    buttonUrl = process.env.CUSTOM_PROJECT_CREATION_URL;
    external = true;
  }

  return (
    <div ref={ref}>
      {kindModal && (
        <ModalTrial
          isOpen={isModalOpen}
          closeModal={() => toggleModal(false)}
          kind={kindModal}
        />
      )}

      <ButtonLink
        id="create_project"
        className="create_project"
        addLink={linkType === "nav"}
        onClick={!canCreate ? openModal : null}
        to={buttonUrl}
        external={external}
        text={intl.formatMessage({ id: "create_project" })}
      />
    </div>
  );
};

NewProjectButton.propTypes = {
  linkType: PropTypes.string,
  organizationId: PropTypes.string,
  user: PropTypes.object
};

export default withReducers({
  paymentSource: () => import("Reducers/paymentSource")
})(NewProjectButton);
