import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import FloatBox from "../FloatBox";
import SearchInput from "Components/SearchInput";
import NewProjectButton from "Components/NewProjectButton";

const SearchBar = ({
  currentPathName,
  onSearchChange,
  organizationId,
  user
}) => {
  const intl = useIntl();
  const [text, setSearch] = useState("");
  const [to, setTo] = useState("");

  return (
    <FloatBox>
      <SearchInput
        id="project-search-input"
        placeholder={
          currentPathName.includes("billing/history")
            ? "Search orders"
            : intl.formatMessage({
                id: "dashboard.search_placeholder"
              })
        }
        onChange={e => {
          const s = e.target.value;
          setSearch(s);

          if (to) {
            clearTimeout(to);
          }

          const timeout = setTimeout(() => {
            onSearchChange(s);
          });

          setTo(timeout);
        }}
        iconSize={18}
        className="float"
        value={text}
      />
      <NewProjectButton
        organizationId={organizationId}
        user={user}
        linkType="nav"
      />
    </FloatBox>
  );
};

SearchBar.propTypes = {
  currentPathName: PropTypes.string,
  organizationId: PropTypes.string,
  onSearchChange: PropTypes.func,
  user: PropTypes.object,
  query: PropTypes.string
};

export default SearchBar;
