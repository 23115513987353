import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import copyToClipboard from "copy-to-clipboard";
import PropTypes from "prop-types";

import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";

import * as S from "./styles";

const CopyButton = ({ className = "", text }) => {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  useEffect(
    () => {
      const timer = setTimeout(() => {
        if (copied) {
          setCopied(false);
        }
      }, 500);
      return () => clearTimeout(timer);
    },
    [copied]
  );

  const copy = link => {
    copyToClipboard(link);

    setCopied(true);
  };

  return (
    <S.LayoutWrapper className={className}>
      <S.Layout
        className="copy-to-clipboard"
        onClick={() => {
          copy(text);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({ id: "icons.copy" })}
      >
        <CopyIcon aria-hidden="true" />
        <span>{intl.formatMessage({ id: "copy" })}</span>
      </S.Layout>
      {copied && (
        <S.Info className="copied-message">
          <SuccessStateIcon color="#fff" />
        </S.Info>
      )}
    </S.LayoutWrapper>
  );
};

CopyButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

export default CopyButton;
