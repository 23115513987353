import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { checkGoLive } from "Libs/utils";

import ButtonLink from "Components/ButtonLink";

import * as S from "./styles";

const LiveButton = ({ className, organizationId, project, subscription }) => {
  const intl = useIntl();

  const subscriptionEditUrl = process.env.ENABLE_CUSTOM_SUBSCRIPTION_URL
    ? subscription && subscription.data._links.plan_uri.href
    : `/-/users/${organizationId}/billing/plan/${subscription?.id}`;

  const getButton = () => {
    if (!project.subscription?.plan) return false;
    const goLive = checkGoLive(project);

    switch (goLive) {
      case "live":
        return (
          <S.Live>
            {intl.formatMessage({
              id: "project.state.live",
              defaultMessage: "Live"
            })}
          </S.Live>
        );
      case "development":
        if (typeof subscription?.getLink !== "function") return;
        return (
          <ButtonLink
            to={subscriptionEditUrl}
            text={intl.formatMessage({
              id: "project.screenshot.state.not_live",
              defaultMessage: "Go live"
            })}
            external={true}
            blank={false}
          />
        );
      case "no-permission":
        return false;
      default:
        return (
          <ButtonLink
            to={`/${organizationId}/${project.id}/-/settings/domains`}
            text={intl.formatMessage({
              id: "project.state.not_live",
              defaultMessage: "Go live"
            })}
          />
        );
    }
  };

  return <S.Wrapper className={className}>{getButton()}</S.Wrapper>;
};

LiveButton.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object,
  subscription: PropTypes.object,
  organizationId: PropTypes.string
};

export default LiveButton;
