import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import NewProjectButton from "Components/NewProjectButton";
import PlatformIconLg from "Icons/PlatformIconLg";
import useMediaQuery from "Hooks/useMediaQuery";
import VendorLogo from "../../Logo";

import Close from "./Close";

import * as S from "./styles";

const MobileMenu = ({ breadcrumbs, user, urls }) => {
  const [isOpen, setIsOpen] = useState(false);
  const match = useMediaQuery("(max-width: 764px)");

  useEffect(
    () => {
      document.querySelector("#app").style.overflow =
        match && isOpen ? "hidden" : null;
    },
    [isOpen, match]
  );

  useEffect(
    () => {
      if (!match) {
        setIsOpen(false);
      }
    },
    [match]
  );

  return (
    match && (
      <S.Layout>
        <Close
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
            document.activeElement.blur();
          }}
        />
        {isOpen && (
          <S.FloatingContainer isOpen={isOpen}>
            <S.FloatingLogo>
              {process.env.VENDOR_URL ? <VendorLogo /> : <PlatformIconLg />}
            </S.FloatingLogo>
            <S.Breadcrumbs>{breadcrumbs}</S.Breadcrumbs>
            <S.UserMenu>
              <li>
                <S.Link onClick={() => setIsOpen(false)} url="/">
                  <FormattedMessage id="menu.projects" />
                </S.Link>
              </li>
              <li>
                <S.Link onClick={() => setIsOpen(false)} url={urls.profileUrl}>
                  <FormattedMessage id="menu.account" />
                </S.Link>
              </li>
              <li>
                <S.Link onClick={() => setIsOpen(false)} url={urls.billingUrl}>
                  <FormattedMessage id="menu.billing" />
                </S.Link>
              </li>
              <li>
                <S.Link onClick={() => setIsOpen(false)} url={urls.supportUrl}>
                  <FormattedMessage id="menu.support" />
                </S.Link>
              </li>
              <li>
                <S.Link
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setIsOpen(false)}
                  url={urls.docsUrl}
                >
                  <FormattedMessage id="menu.documentation" />
                </S.Link>
              </li>
              <li>
                <NewProjectButton
                  onClick={() => setIsOpen(false)}
                  linkType="nav"
                  user={user}
                />
              </li>
            </S.UserMenu>

            <S.BottomRow>
              <S.UserInfo>
                <S.UserProfile src={user?.picture} alt={user?.display_name} />
                <S.UserName>{user?.display_name}</S.UserName>
              </S.UserInfo>
              <S.LogOut url={urls.logoutUrl}>Logout</S.LogOut>
            </S.BottomRow>
          </S.FloatingContainer>
        )}
      </S.Layout>
    )
  );
};

MobileMenu.propTypes = {
  breadcrumbs: PropTypes.node,
  user: PropTypes.object,
  urls: PropTypes.object
};

export default MobileMenu;
