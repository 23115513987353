import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { goToEnvironment } from "Libs/utils";

import { EnvironmentListBar } from "../";

import * as S from "./styles";

const List = ({ node, titleFilter, organizationId }) => {
  const { push } = useHistory();

  return (
    <S.Wrapper
      className={`environment-row depth-${node.depth} ${
        node.isRoot ? "root" : "child"
      }${node.status == "inactive" ? " inactive" : " active"}`}
    >
      <EnvironmentListBar
        node={node}
        organizationId={organizationId}
        className={`environment-list-row ${
          node.status === "inactive" ? "inactive" : ""
        }`}
        onClick={() =>
          goToEnvironment(
            push,
            organizationId,
            node.projectId,
            encodeURIComponent(node.environmentId)
          )
        }
        titleFilter={titleFilter}
        list={true}
      />
    </S.Wrapper>
  );
};

List.propTypes = {
  organizationId: PropTypes.string.isRequired,
  node: PropTypes.object,
  titleFilter: PropTypes.string
};

export default List;
