import { useState, useCallback, useEffect } from "react";
import localforage from "localforage";

/**
 *
 * @param {String} key Localforage key
 *
 * @return {[value, setValue, error, isLoading]}
 * value: any
 *  The first element of the array is the stored value of the key.
 *  Since all the methods exposed by localForage are async, the initial value is
 *  always undefined until the storage has been succesfully read.
 *  If the key is not present on the store, that undefined will change to null.
 *  If there's a value already stored, that value will be returned.
 *  If there's an error, the value will remain as undefined.
 * setValue: (value: any) => void
 *  Persists the value.
 * error: Error
 * isLoading: True during the initial load
 */
const useLocalForage = key => {
  const [error, setError] = useState();
  const [keyValue, setKeyValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      (async () => {
        try {
          setIsLoading(true);
          const value = await localforage.getItem(key);
          setKeyValue(typeof value === "undefined" ? null : value);
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
        }
      })();
    },
    [key]
  );

  const setValue = useCallback(
    async value => {
      try {
        await localforage.setItem(key, value);
        setKeyValue(value);
      } catch (err) {
        setError(err);
      }
    },
    [key]
  );

  return [keyValue, setValue, error, isLoading];
};

export default useLocalForage;
