import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Layout = styled.li`
  list-style: none;
  span.icon svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const NodeLayout = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;

  img + span {
    margin-left: 10px;
  }
  &.depth-0 {
    margin-bottom: 16px;
  }
  &.depth-1 {
    margin-bottom: 20px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background: ${props =>
            props.theme.name === "dark"
              ? themeHelpers(props.theme, "darkTint")
              : props.theme.success} !important;
          svg path {
            fill: ${props =>
              props.theme.name === "dark" ? props.theme.success : "#fff"};
          }
        }
      }
    }
    .title {
      ${semiBoldAlias};
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .list-bar-left {
    .icon {
      svg path {
        fill: ${props => props.theme.primary};
      }
      &.inactive {
        svg path {
          fill: ${props => props.theme.envTreeInactiveIcon};
        }
      }
    }
  }
  .inactive {
    .title {
      color: ${props => props.theme.envTreeInactiveText};
    }
  }
  &.has-children {
    .list-bar-left {
      .icon {
        background: ${props =>
          props.theme.name === "dark"
            ? themeHelpers(props.theme, "darkTint")
            : props.theme.primary};
        svg path {
          fill: ${props =>
            props.theme.name === "dark" ? props.theme.primary : "#fff"};
        }
        &.inactive {
          background: ${props => props.theme.envTreeInactiveIconBg} !important;
          svg path {
            fill: ${props => props.theme.envTreeInactiveIcon};
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    li.root li.environment-row {
      padding-left: 32px !important;
    }
  }
`;

export const HorizontalLine = styled.div`
  &::after {
    position: absolute;
    content: "";
    background-color: ${props => props.theme.environmentTreeLine};
    height: 1px;
    top: ${props => props.top};
    left: ${props => props.left};
    width: ${props => props.size};
  }
`;

export const VerticalLine = styled.div`
  &::after {
    position: absolute;
    content: "";
    background-color: ${props => props.theme.environmentTreeLine};
    width: 1px;
    left: ${props => props.left};
    top: ${props => props.top};
    height: ${props => props.size};
  }
`;
