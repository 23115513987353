import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import withReducers from "Hocs/withReducers";
import { getPaymentSource } from "Reducers/paymentSource";

import { hasSafeRole } from "Libs/utils";

import { PaymentMissing, Trial } from "./";

const BannerManager = ({ isProjectOwner = true, user }) => {
  const dispatch = useDispatch();
  const useBrandedArt = !process.env.ENABLE_UNBRANDED_ILLUSTRATIONS;

  const loadingPaymentSource = useSelector(state =>
    state.paymentSource?.get("loading")
  );

  const hasPaymentSource = useSelector(state => {
    const ps = state.paymentSource?.get("data");
    return !!ps?.type;
  });

  useEffect(() => {
    if (!process.env.ENABLE_ORGANIZATION && loadingPaymentSource === "idle")
      dispatch(getPaymentSource());
  }, []);

  // We avoid showing the banner if we are still fetching
  // a payment source, so we don't flash the banner
  if (loadingPaymentSource) {
    return null;
  }

  if (hasPaymentSource || hasSafeRole(user.roles)) {
    return null;
  }

  // User is on Trial mode
  if (user.trial && user.data?.current_trial?.active)
    return <Trial user={user.data} useBrandedArt={useBrandedArt} />;

  if (!user.data.current_trial?.active && !hasPaymentSource && isProjectOwner)
    return <PaymentMissing />;

  return null;
};

BannerManager.propTypes = {
  isProjectOwner: PropTypes.bool,
  user: PropTypes.object
};

export default withReducers({
  paymentSource: () => import("Reducers/paymentSource")
})(BannerManager);
