import React from "react";
import PropTypes from "prop-types";

import UserAvatar from "react-user-avatar";
import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./styles";

const COLORS = [
  "#ffcdd2",
  "#e1bee7",
  "#c5cae9",
  "#b3e5fc",
  "#b2dfdb",
  "#dcedc8",
  "#fff9c4",
  "#ffe0b2",
  "#d7ccc8",
  "#f8bbd0",
  "#d1c4e9",
  "#bbdefb",
  "#b2ebf2",
  "#c8e6c9",
  "#f0f4c3",
  "#ffecd3",
  "#ffccbc"
];

const Avatar = ({ className, name, picture = "", size = 24 }) => {
  const getClipName = () => {
    if (!name) return "N/A";

    let clipName = name.split(/[\s.]+/);
    return clipName.slice(0, 2).join(" ");
  };

  const pictureSrc = picture.includes("users-default.png") ? "" : picture;

  const clipName = getClipName();
  return (
    <S.Wrapper className={className}>
      <AccessibleTooltip
        tooltipProps={{
          id: `user-${clipName}`,
          children: name ? name : "Not available"
        }}
      >
        <UserAvatar
          size={size}
          name={clipName}
          src={pictureSrc}
          colors={COLORS}
        />
      </AccessibleTooltip>
    </S.Wrapper>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.string,
  size: PropTypes.number
};

export default Avatar;
