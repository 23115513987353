import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import AlertIcon from "Icons/AlertIcon";
import { Wrapper } from "../";

const PaymentMissing = () => {
  const intl = useIntl();
  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  return (
    <Wrapper type={"critical"}>
      <AlertIcon color="#FFF" />
      {intl.formatMessage(
        {
          id: "banner.payment_missing",
          defaultMessage:
            "Payment method missing. Add your <a>billing details</a> to keep your projects running."
        },
        {
          // eslint-disable-next-line react/display-name
          a: txt => <Link to={`/-/users/${username}/billing`}>{txt}</Link>
        }
      )}
    </Wrapper>
  );
};

export default PaymentMissing;
