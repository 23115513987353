import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getPaymentSource = createAsyncThunk(
  "app/paymentSource",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const paymentSource = await client.getPaymentSource();
    return paymentSource;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const paymentSource = createSlice({
  name: "paymentSource",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {},
  extraReducers: {
    [getPaymentSource.pending]: state => state.set("loading", true),
    [getPaymentSource.fulfilled]: (state, action) =>
      state.setIn(["data"], fromJS(action.payload)).set("loading", false),
    [getPaymentSource.rejected]: (state, action) => setError(state, action)
  }
});

export default paymentSource.reducer;
