import {
  APP_NAME,
  CLI_COMMAND_NAME,
  CLI_DOWNLOAD_URL,
  DOCS_CLI_REPO_URL,
  DOCS_CLI_URL,
  DOCS_CREATE_NEW_PROJECT_URL,
  DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL,
  DOCS_USING_SSH_KEYS_URL,
  DOCS_YAML_CONFIG_URL,
  DOCS_SERVICES_CONFIG_URL,
  DOCS_APP_CONTAINERS_CONFIG_URL,
  DOCS_ROUTES_CONFIG_URL,
  DOCS_MIGRATING_TUTORIAL_URL,
  DOCS_IMPORTING_DATA_MYSQL_URL,
  DOCS_IMPORTING_DATA_POSTGRESQL_URL
} from "../documentationUrls";

export const BLANK_WIZARD_STEPS = [
  {
    id: "cli",
    label: "Download the CLI",
    title: "Download the CLI",
    required: false,
    bodyText: `<p>To install the CLI (Command Line Interface), run the following command.</p><p>For more info about our CLI check out our <a href="${DOCS_CLI_URL}" target="_blank" rel="noopener noreferrer">documentation</a> or take a look at our <a href=${DOCS_CLI_REPO_URL} target="_blank" rel="noopener noreferrer">CLI source code</a> which provides alternate installation methods.</p>`,
    copyCode: [
      {
        label:
          "OSX or Linux Installer and Windows (with Windows Subsystem for Linux)",
        code: [`curl -sS ${CLI_DOWNLOAD_URL} | php`]
      }
    ]
  },
  {
    id: "sshKey",
    label: "Add your SSH key",
    title: "Add your SSH key",
    required: true,
    bodyText: `<p>To deploy your code to ${APP_NAME} you'll need Git installed on your local machine and your <a href=${DOCS_USING_SSH_KEYS_URL} target="_blank" rel="noopener noreferrer">SSH key</a>. You can paste your Public SSH key in the box below (or do so later in your Account Settings).</p><p>Configuring an SSH key will also allow you to connect to your running applications and services.</p><p>If you don't have an SSH key configured we recommend following Github's excellent SSH <a href="https://help.github.com/en/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent" target="_blank" rel="noopener noreferrer">tutorial</a>.</p>`
  },
  {
    id: "remote",
    label: `Set ${APP_NAME} remote`,
    title: `Set ${APP_NAME} as a remote so you can push code to ${APP_NAME}`,
    required: true,
    bodyText: `<p>If you have a local Git repository already available, add your ${APP_NAME} repository as a new remote using the ${APP_NAME} CLI <code>project:set-remote</code> command. If you have not already initialized your project directory as a Git repository, you will need to do this first.</p><p>Visit our <a href=${DOCS_CREATE_NEW_PROJECT_URL} target="_blank" rel="noopener noreferrer">docs</a> for more information.</p>`,
    copyCode: [
      {
        label: "Set Git remote",
        code: [`${CLI_COMMAND_NAME} project:set-remote <project ID>`]
      }
    ]
  },
  {
    id: "yaml",
    label: "Configure your project",
    title: `To deploy your project you must first add ${APP_NAME} configuration YAML files`,
    required: true,
    bodyText: `<p>You can start by creating empty YAML files. In the next steps we will help you fill them in.</p><p><a href=${DOCS_YAML_CONFIG_URL} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring the YAML files</p>`,
    copyCode: [
      {
        label: "Application code structure",
        code: [
          " ├── .platform",
          " │   ├── routes.yaml",
          " │   └── services.yaml",
          " ├── .platform.app.yaml",
          " └── < application code >"
        ],
        codeType: "noCopy"
      },
      {
        label: "Create empty files",
        code: [
          "mkdir .platform/",
          "touch .platform/routes.yaml",
          "touch .platform/services.yaml",
          "touch .platform.app.yaml"
        ]
      }
    ]
  },
  {
    id: "services",
    label: "Configure services",
    title: "Configure your services",
    required: false,
    bodyText: `<p>${APP_NAME} comes with fully managed services with databases such as MySQL or Postgres, caches like Redis or Varnish and search-engines like Solr. You can leave this blank for now and configure them later or use the dropdown to compose your <code>services.yaml</code> file with code examples. Your sample file will include the latest versions we offer.</p><p><a href=${DOCS_SERVICES_CONFIG_URL} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring your services.</p>`
  },
  {
    id: "application",
    label: "Configure applications",
    title: "Configure your application",
    required: true,
    bodyText: `<p>The <code>.platform.app.yaml</code> file defines how your application is built, deployed and run. You should define at a minimum: a unique <code>name</code>, <code>type</code> (the runtime), the size of your persistent <code>disk</code> and <code>web</code>.</p><p>If you configured services in the previous step, pay attention to how those services are defined in the <code>relationships</code> block.</p><p>Take a look at our <a href=${DOCS_APP_CONTAINERS_CONFIG_URL} target="_blank" rel="noopener noreferrer">documentation</a> for more detailed examples and for instructions on deploying multiple applications and workers in your cluster.</p>`
  },
  {
    id: "routes",
    label: "Configure routes",
    title: "Configure the HTTP router",
    required: true,
    bodyText: `<p>${APP_NAME} supports multiple applications in a single cluster as well as advanced caching and redirects. The <code>.platform/routes.yaml</code> file defines those. The example below routes all the requests to a single application and redirects the <pre>www.</pre> subdomain to the root domain.</p><p><a href=${DOCS_ROUTES_CONFIG_URL} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring your routes.</p>`
  },
  {
    id: "push",
    label: "Commit & push",
    title: `Commit and push your configuration files to ${APP_NAME}`,
    required: true,
    bodyText: `<p>To deploy on ${APP_NAME} add the required configuration files to your Git repository: </p><p><label>Required files:</label><br><a href=${DOCS_APP_CONTAINERS_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform.app.yaml</code></a>, <a href=${DOCS_SERVICES_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform/services.yaml</code></a>, and <a href=${DOCS_ROUTES_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform/routes.yaml</code></a></p><p>Then commit and push them using the following commands:</p><br><p>See our <a href=${DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL} target="_blank" rel="noopener noreferrer">documentation</a> for more information.</p>`,
    copyCode: [
      {
        label: "Commit and push",
        code: [
          "git add .",
          'git commit -m "Add config files."',
          `git push -u ${CLI_COMMAND_NAME} master`
        ],
        multiLine: true
      },
      {
        label: "Verify deployment",
        code: [`${CLI_COMMAND_NAME} url`]
      }
    ]
  },
  {
    id: "database",
    label: "Import existing database",
    title: `Import an existing database to ${APP_NAME}`,
    required: false,
    bodyText: `<p>If you run <a href=${DOCS_IMPORTING_DATA_MYSQL_URL} target="_blank" rel="noopener noreferrer">MySQL</a> or <a href=${DOCS_IMPORTING_DATA_POSTGRESQL_URL} target="_blank" rel="noopener noreferrer">Postgres</a>, you can use the CLI’s <code>${CLI_COMMAND_NAME} sql</code> command to pipe a database dump onto the ${APP_NAME} database.</p><p>Check our <a href=${DOCS_MIGRATING_TUTORIAL_URL}>documentation</a> to find-out more about data migration.</p>`,
    copyCode: [
      {
        label: "Run the commands",
        code: [`${CLI_COMMAND_NAME} sql < my_database_snapshot.sql`]
      }
    ]
  },
  {
    id: "files",
    label: "Import additional files",
    title: "Import additional files",
    required: false,
    bodyText: `<p>${APP_NAME} supports persistent mounts to which your applications can write. These are defined in the <code>.platform.app.yaml</code> file.</p><p>If you have existing files you want to upload the CLI can guide you through the process. </p>`,
    copyCode: [
      {
        label: "Import files",
        code: [
          `${CLI_COMMAND_NAME} mount:upload --mount web/uploads --source ./local_files`
        ]
      }
    ]
  }
];

export default {
  BLANK_WIZARD_STEPS
};
