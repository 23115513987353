import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import copyToClipboard from "copy-to-clipboard";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";

import {
  getOrganizationDescriptionIdFromProject,
  interpolateURL,
  isProjectOwner
} from "Libs/utils";
import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import IconMore from "Icons/IconMore";
import ActionDropdown from "../ActionDropdown";
import ModalConfirmDelete from "../ModalConfirmDelete";

import { deleteSubscription } from "Reducers/subscription";

import * as S from "./styles";

const ProjectMoreLinks = ({
  iconColor = "white",
  from,
  project,
  subscription,
  toggleProjectWizard
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [modalIsOpen, setOpenModal] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const me = useSelector(state => state.app?.getIn(["me"]));
  const organizations = useSelector(state =>
    state.organization?.getIn(["data"], new Map())
  );
  const subscriptionStatus = useSelector(({ subscription }) =>
    subscription?.get("status")
  );

  useEffect(
    () => {
      let isCanceled = false;
      let timer;
      if (isCopied) {
        timer = setTimeout(() => !isCanceled && setIsCopied(false), 500);
      }
      return () => {
        isCanceled = true;
        clearTimeout(timer);
      };
    },
    [isCopied]
  );

  useEffect(
    () => {
      if (subscriptionStatus === "deleted")
        window.location.replace(window.location.origin);
    },
    [subscriptionStatus]
  );

  const organizationId = getOrganizationDescriptionIdFromProject(
    project,
    organizations?.toJS()
  );

  const subscriptionEditUrl = process.env.ENABLE_CUSTOM_SUBSCRIPTION_URL
    ? subscription && subscription.data._links.plan_uri.href
    : `/-/users/${organizationId}/billing/plan/${project.subscription_id}`;

  const isSubscriptionEditExternal = /^https?:\/\//.test(subscriptionEditUrl);

  const copyId = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    setIsCopied(true);
    copyToClipboard(project.id);
  };

  const openModal = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: project.id }
      );
    } else {
      setOpenModal(true);
    }
  };

  const handleDelete = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();

    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: project.id }
      );
    } else {
      dispatch(deleteSubscription({ subscription }));
    }
  };

  const handleEditSubscription = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    window.location.href = subscriptionEditUrl;
  };

  let hasPermission = false;

  if (process.env.ENABLE_ORGANIZATION) {
    hasPermission =
      subscription?.hasPermission &&
      subscription.hasPermission("#manage-subscription");
  } else {
    hasPermission =
      (project?.hasPermission &&
        project.hasPermission("#subscription_change")) ||
      isProjectOwner(project, me.toJS(), organizations?.toJS());
  }
  if (!hasPermission && !toggleProjectWizard) {
    return false;
  }

  return (
    <S.Wrapper>
      <ActionDropdown
        icon={<IconMore color={iconColor} />}
        ariaLabel="More"
        withArrow={false}
        className="more"
        id={project.subscription_id}
      >
        <ul>
          <li role="menuitem">
            <S.IdProject onClick={copyId}>
              {intl.formatMessage(
                { id: "project.morelinks.id", defaultMessage: "ID {id}" },
                {
                  id: project.id
                }
              )}
              <S.Icon>
                {isCopied ? (
                  <SuccessStateIcon aria-hidden="true" />
                ) : (
                  <CopyIcon aria-hidden="true" />
                )}
              </S.Icon>
            </S.IdProject>
          </li>

          {hasPermission &&
            subscriptionEditUrl && (
              <li role="menuitem">
                {isSubscriptionEditExternal ? (
                  <S.Button onClick={handleEditSubscription}>
                    {intl.formatMessage({
                      id: "project.morelinks.edit",
                      defaultMessage: "Edit plan"
                    })}
                  </S.Button>
                ) : (
                  <Link
                    to={{
                      pathname: subscriptionEditUrl,
                      state: { from }
                    }}
                  >
                    {intl.formatMessage({
                      id: "project.morelinks.edit",
                      defaultMessage: "Edit plan"
                    })}
                  </Link>
                )}
              </li>
            )}

          {toggleProjectWizard && (
            <li role="menuitem">
              <S.Button onClick={toggleProjectWizard}>
                {intl.formatMessage({
                  id: "project.morelinks.wizard",
                  defaultMessage: "Setup wizard"
                })}
              </S.Button>
            </li>
          )}

          {hasPermission && (
            <li role="menuitem">
              <S.Button onClick={e => openModal(e)}>
                {intl.formatMessage({
                  id: "project.morelinks.delete",
                  defaultMessage: "Delete"
                })}
              </S.Button>
            </li>
          )}
        </ul>
      </ActionDropdown>

      <ModalConfirmDelete
        title={intl.formatMessage({
          id: "project.morelinks.delete_modal.title",
          defaultMessage: "Delete project"
        })}
        deleteFunction={handleDelete}
        isOpen={modalIsOpen}
        closeModal={() => setOpenModal(false)}
        itemId={`subscription-${project.subscription_id}`}
        size="medium"
        body={intl.formatMessage(
          {
            id: "project.morelinks.delete_modal.sentence"
          },
          {
            sub: project.title,
            br: <br />,
            b: (...chunks) => <strong>{chunks}</strong> //eslint-disable-line
          }
        )}
      />
    </S.Wrapper>
  );
};

ProjectMoreLinks.propTypes = {
  iconColor: PropTypes.string,
  from: PropTypes.string,
  project: PropTypes.object,
  subscription: PropTypes.object,
  toggleProjectWizard: PropTypes.func
};

export default ProjectMoreLinks;
