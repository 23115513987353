import React, { useState } from "react";
import PropTypes from "prop-types";

import Link from "./Link";
import InfoIcon from "Icons/InfoIcon";

import * as S from "./styles";

const InfoDialog = ({
  align = "left",
  className,
  icon,
  linkText,
  linkText2,
  text,
  title,
  to,
  to2
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleKeyUp = event => {
    if (event.which === 27) document.activeElement.blur();
  };

  return (
    <S.Wrapper
      className={`info-dialog${className ? " " + className : ""}`}
      tabIndex="0"
      onKeyUp={handleKeyUp}
      isHover={isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {icon ? icon : <InfoIcon />}
      {(title || text || to) && (
        <S.DialogWrapper
          align={align}
          className={`dialog${align ? " align-" + align : ""}`}
        >
          <S.DialogContent>
            {title && <S.Title>{title}</S.Title>}
            {text && <S.Text>{text}</S.Text>}
            {to && <Link to={to}>{linkText ? linkText : to}</Link>}
            {to2 && <Link to={to2}>{linkText2 ? linkText2 : to2}</Link>}
          </S.DialogContent>
        </S.DialogWrapper>
      )}
    </S.Wrapper>
  );
};

InfoDialog.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  linkText: PropTypes.string,
  linkText2: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  to: PropTypes.string,
  to2: PropTypes.string
};

export default InfoDialog;
