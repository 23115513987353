import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const Close = ({ isOpen, ...props }) => (
  <S.Layout
    isOpen={isOpen}
    aria-label={isOpen ? `Open menu` : "Close menu"}
    {...props}
  >
    <S.Line isOpen={isOpen} />
    <S.Line isOpen={isOpen} />
  </S.Layout>
);

Close.propTypes = {
  isOpen: PropTypes.bool
};

export default Close;
