export const PROJECT_ID_FIELD = "id";
export const ORGANIZATION_ID_FIELD = "name";
export const ENVIRONMENT_ID_FIELD = "id";
export const INTEGRATION_ID_FIELD = "id";
export const TEAM_ID_FIELD = "name";
export const SCREENSHOT_API = process.env.SCREENSHOT_API;
export const ADMIN_ROLES = ["administrator", "team member"];
export const SAFE_ROLES = [...ADMIN_ROLES, "invoice account"];
export const BILLING_PATH_MATCH = /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/billing/;
export const SETTINGS_PATH_MATCH = /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/settings/;
export const SUPPORT_PATH_MATCH = /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/tickets/;
export const INTEGRATIONS_KINDS = {
  git: ["bitbucket", "bitbucket_server", "github", "gitlab"],
  health: [
    "health.email",
    "health.pagerduty",
    "health.slack",
    "health.webhook"
  ],
  custom: ["hipchat", "script", "webhook", "newrelic", "sumologic"]
};
export const PLAN_TYPES = {
  "trial/development": "Development",
  small: "Small",
  standard: "Standard",
  medium: "Medium",
  large: "Large",
  xlarge: "Xlarge",
  "2xlarge": "2xlarge"
};
export const ENVIRONMENT_TYPES = ["production", "staging", "development"];
