import styled from "styled-components";

import SubNavBar from "Components/SubNavBar";
import * as SNavBar from "Components/SubNavBar/styles";
import * as STabs from "Components/SubNavBar/Tabs/styles";

export const NavBar = styled(SubNavBar)`
  ${SNavBar.SubNavBarLayout} {
    height: 34px;
    min-height: 34px;
    max-height: 34px;
    padding: 0;

    a:not(.button) {
      font-size: 13px;
      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.subNavActiveRev};
      }

      &.active:before {
        background: ${props => props.theme.subNavActiveRev};
      }
    }

    @media (min-width: 768px) {
      ${SNavBar.SubNavBarTabWrapper} {
        padding-top: 18px;
        padding-left: 0;
        box-sizing: content-box;
      }

      a:not(.button) {
        padding-left: 0;
        &.active {
          &:before {
            left: 3px;
          }
        }
      }
    }
  }

  ${STabs.DropdownTab}, ${STabs.Tab} {
    a:not(.button) {
      color: ${props => props.theme.subNavTextRev};
      &.active {
        color: ${props => props.theme.subNavActiveRev};
      }
    }
  }
`;
