import styled from "styled-components";
import { shade, saturate } from "polished";

export const Main = styled.main`
  display: inline-block;
  min-height: calc(100vh - 98px - 63px);
  width: 100%;
  padding: 0 24px;
  background: ${props =>
    props.theme.name === "dark"
      ? saturate(0.07, shade(0.4, props.theme.dark))
      : props.theme.background};
  color: ${props => props.theme.text};
  &.project {
    min-height: calc(100vh - 120px - 63px);
  }
  .type-dark,
  &.theme-dark .step-wrapper {
    box-shadow: ${props =>
      props.theme.name === "dark" ? props.theme.menuDropShadow : "none"};
  }
  &.theme-dark .step-wrapper {
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    padding: 0 40px;
    &.create-project {
      padding-top: 0;
    }
  }
  @media (min-width: 1920px) {
    padding: 0 60px;
  }
  &.billing-details {
    @media (min-width: 768px) {
      padding: 0 40px;
    }
    @media (min-width: 1920px) {
      padding: 0 60px;
    }
  }
`;
