export const CLI_COMMAND_NAME = process.env.CUSTOM_CLI_COMMAND || "platform";

export const APP_NAME = process.env.CUSTOM_APP_NAME || "Platform.sh";

export const CLI_DOWNLOAD_URL =
  process.env.CUSTOM_CLI_DOWNLOAD_URL || "https://platform.sh/cli/installer";

export const CONFIG_DIR_NAME =
  process.env.CUSTOM_CONFIG_DIR_NAME || ".platform";

export const DOCS_URL =
  process.env.CUSTOM_DOCS_URL || "https://docs.platform.sh";

export const DOCS_ADMIN_BACKUPS_URL =
  process.env.CUSTOM_DOCS_ADMIN_BACKUPS_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/administration/web.html#backup";

export const DOCS_APP_CONTAINERS_CONFIG_URL =
  process.env.CUSTOM_DOCS_APP_CONTAINERS_CONFIG_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/app-containers.html";

export const DOCS_CLI_API_TOKENS_URL =
  process.env.CUSTOM_DOCS_CLI_API_TOKENS_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/development/cli/api-tokens.html";

export const DOCS_AVAILABILITY_INCIDENT_URL =
  process.env.CUSTOM_DOCS_AVAILABILITY_INCIDENT_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/dedicated/overview/monitoring.html#availability-incident-handling-procedure";

export const DOCS_CLI_GETTING_STARTED_URL =
  process.env.CUSTOM_DOCS_CLI_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted/cli.html";

export const DOCS_CLI_REPO_URL =
  process.env.CUSTOM_DOCS_CLI_REPO_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://github.com/platformsh/platformsh-cli";

export const DOCS_CLI_URL =
  process.env.CUSTOM_DOCS_CLI_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/development/cli.html#cli-command-line-interface";

export const DOCS_CONFIG_APP_VARIABLES_URL =
  process.env.CUSTOM_DOCS_CONFIG_APP_VARIABLES_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/app/variables.html#variables";

export const DOCS_CREATE_NEW_PROJECT_URL =
  process.env.CUSTOM_DOCS_CREATE_NEW_PROJECT_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted/own-code/create-project.html#create-a-new-project";

export const DOCS_DEDICATED_CLUSTER_URL =
  process.env.CUSTOM_DOCS_DEDICATED_CLUSTER_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/dedicated/overview.html";

export const DOCS_DNS_URL =
  process.env.CUSTOM_DOCS_DNS_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/golive/steps/dns.html";

export const DOCS_GETTING_STARTED_DOWNLOAD_CODE_URL =
  process.env.CUSTOM_DOCS_GETTING_STARTED_DOWNLOAD_CODE_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted/local-development/download-code.html";

export const DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL =
  process.env.CUSTOM_DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted/own-code/project-configuration.html";

export const DOCS_GETTING_STARTED_URL =
  process.env.CUSTOM_DOCS_GETTING_STARTED_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted.html";

export const DOCS_GLOSSARY_INACTIVE_ENVIRONMENT_URL =
  process.env.CUSTOM_DOCS_GLOSSARY_INACTIVE_ENVIRONMENT_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/GLOSSARY.html#inactive-environment";

export const DOCS_GOLIVE_CHECKLIST_URL =
  process.env.CUSTOM_DOCS_GOLIVE_CHECKLIST_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/gettingstarted/next-steps/going-live.html";

export const DOCS_IMPORTING_DATA_MYSQL_URL =
  process.env.CUSTOM_DOCS_IMPORTING_DATA_MYSQL_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/services/mysql.html#importing-data";

export const DOCS_IMPORTING_DATA_POSTGRESQL_URL =
  process.env.CUSTOM_DOCS_IMPORTING_DATA_POSTGRESQL_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/services/postgresql.html#importing-data";

export const DOCS_INTEGRATIONS_URL =
  process.env.CUSTOM_DOCS_INTEGRATIONS_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/integrations/overview.html";

export const DOCS_MIGRATING_TUTORIAL_URL =
  process.env.CUSTOM_DOCS_MIGRATING_TUTORIAL_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/tutorials/migrating.html";

export const DOCS_OWN_DOMAIN_URL =
  process.env.CUSTOM_DOCS_OWN_DOMAIN_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/golive/steps.html#2-set-your-domain-in-platformsh";

export const DOCS_PRICING_URL =
  process.env.CUSTOM_DOCS_PRICING_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/overview/pricing.html";

export const DOCS_ROUTES_CONFIG_URL =
  process.env.CUSTOM_DOCS_ROUTES_CONFIG_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/routes.html";

export const DOCS_ROUTES_SSI_CONFIG_URL =
  process.env.CUSTOM_DOCS_ROUTES_SSI_CONFIG_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/routes/ssi.html";

export const DOCS_SERVICES_CONFIG_URL =
  process.env.CUSTOM_DOCS_SERVICES_CONFIG_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/services.html";

export const DOCS_USING_SSH_KEYS_URL =
  process.env.CUSTOM_DOCS_USING_SSH_KEYS_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/development/ssh.html#using-ssh-keys";

export const DOCS_VARIABLES_URL =
  process.env.CUSTOM_DOCS_VARIABLES_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/development/variables.html";

export const DOCS_YAML_CONFIG_URL =
  process.env.CUSTOM_DOCS_YAML_CONFIG_URL ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/yaml.html";

export const DOCS_APP_MOUNTS =
  process.env.DOCS_APP_MOUNTS ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/app/storage.html#mounts";

export const DOCS_APP_MAIN =
  process.env.DOCS_APP_MAIN ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/app.html";

export const DOCS_ENV_TYPES =
  process.env.DOCS_ENV_TYPES ||
  process.env.CUSTOM_DOCS_URL ||
  process.env.CUSTOM_DOCS_WCAG_INFO_URL ||
  "https://docs.platform.sh/administration/users.html";

export const CUSTOM_DOCS_WCAG_INFO_URL =
  process.env.CUSTOM_DOCS_URL ||
  process.env.CUSTOM_DOCS_WCAG_INFO_URL ||
  "https://platform.sh/wcag/";

export const DOCS_METRICS_DEDICATED =
  process.env.CUSTOM_DOCS_METRICS_DEDICATED ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/dedicated/architecture/metrics.html";

export const DOCS_INTEGRATIONS_ACTIVITY =
  process.env.CUSTOM_DOCS_INTEGRATIONS_ACTIVITY ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/integrations/activity.html";

export const DOCS_INTEGRATIONS_CONFIGURING_SCRIPTS =
  process.env.CUSTOM_DOCS_INTEGRATIONS_CONFIGURING_SCRIPTS ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/integrations/activity.html#configuring-scripts";

export const DOCS_INTEGRATIONS_SOURCE =
  process.env.CUSTOM_DOCS_INTEGRATIONS_SOURCE ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/integrations/source.html";

export const DOCS_CONFIG_APP_TIMEZONE =
  process.env.CUSTOM_DOCS_CONFIG_APP_TIMEZONE ||
  process.env.CUSTOM_DOCS_URL ||
  "https://docs.platform.sh/configuration/app/timezone.html";

export const DOCS_STATUS_CHECK_URL = process.env.CUSTOM_DOCS_STATUS_CHECK_URL;
