import { css, useTheme } from "styled-components";
import { tint, shade, saturate, transparentize } from "polished";

import { palette } from "Libs/themes/legacyThemes";

export const semiBoldAlias = ({ fontWeight = 600 } = {}) => `
    font-family: "Open sans", sans-serif;
    font-weight: ${fontWeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `;

export const shadows = {
  sm: css`
    box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
  `,
  md: css`
    box-shadow: 0px 4px 8px rgba(152, 160, 171, 0.4);
  `,
  lg: css`
    box-shadow: 0px 6px 24px rgba(152, 160, 171, 0.4);
  `,
  xl: css`
    box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
  `
};

export const breakpoints = [
  "32rem", // 512
  "48rem", // 768
  "64rem", // 1024
  "80rem", // 1280
  "96rem", // 1536
  "112rem", // 1792
  "128rem" // 2048
];

export const themeHelpers = (theme, type) => {
  // Helps to ensure that preview settings have appropriate functions.
  if (!theme || !type) {
    return;
  }
  // Navbar BG and Darkmode BG.
  if (type === "darkShade" && theme.dark) {
    return saturate(0.07, shade(0.4, theme.dark));
  }
  // Navbar Hover/Active menu, inactive branches
  if (type === "darkTint" && theme.dark) {
    return tint(0.13, theme.dark);
  }
  // Env URL and settings hover BG.
  if (type === "linkTint" && theme.links) {
    return tint(0.88, theme.links);
  }
  // Env URL and settings hover BG.
  if (type === "primaryTint" && theme.primary) {
    return tint(0.88, theme.primary);
  }
  // Menu hover BG.
  if (type === "linkHover" && theme.links) {
    return transparentize(0.87, theme.links);
  }
  // Focus box shadows, text code.
  if (type === "primaryHover" && theme.primary) {
    return transparentize(0.87, theme.primary);
  }
  // Focus box shadows, text code.
  if (type === "primaryShade" && theme.primary) {
    return saturate(1, shade(0.146, theme.primary));
  }
  // Button hover BG.
  if (type === "buttonShade" && theme.buttons) {
    return saturate(1, shade(0.146, theme.buttons));
  }
  // Modal overlay BG.
  if (type === "overlayColor" && theme.dark) {
    return transparentize(0.1, saturate(0.07, shade(0.4, theme.dark)));
  }
  // Helpers to calculate contrast modes.
  if (type === "primaryColor" && theme.primary) {
    return theme.name === "contrast"
      ? saturate(1, shade(0.146, theme.primary))
      : theme.primary;
  }
  if (type === "buttonColor" && theme.buttons) {
    return theme.name === "contrast"
      ? saturate(1, shade(0.146, theme.buttons))
      : theme.buttons;
  }
  if (type === "buttonHover" && theme.buttons) {
    return theme.name === "contrast"
      ? saturate(1, shade(0.3, theme.buttons))
      : saturate(1, shade(0.146, theme.buttons));
  }
  if (type === "navBarColor" && theme.dark) {
    return theme.name === "contrast"
      ? saturate(0.07, shade(0.4, theme.dark))
      : theme.dark;
  }
  // Helpers to calculate dark modes.
  if (type === "sectionColor" && theme.dark) {
    return theme.name === "dark"
      ? transparentize(0.6, theme.dark)
      : theme.sectionBg;
  }
  if (type === "dropdownColor" && theme.dark) {
    return theme.name === "dark"
      ? saturate(0.03, shade(0.2, theme.dark))
      : theme.sectionBg;
  }
  if (type === "settingBarColor" && theme.dark) {
    return theme.name === "dark"
      ? transparentize(0.5, saturate(0.07, shade(0.4, theme.dark)))
      : theme.settingBarActive;
  }
};

/**
 *
 * Usage
 *
 * ```styles.js
 *  import styled from "styled-components";
 *
 *  import { makePalette } from "Libs/theme";
 *
 *  const theme = makePalette((palette, props) => ({
 *    section_background: {
 *      default: palette.snow,
 *      dark: palette.ebony
 *    },
 *    section_color: {
 *      default: props.isActive ? palette.danger : "transparent"
 *    }
 *  }))
 *
 * const Section = styled.section`
 *  background-color: ${theme.section_background};
 * `;
 * ```
 */
export const makePalette = getValues =>
  Object.keys(getValues({}, {})).reduce(
    (acc, key) => ({
      ...acc,
      [key]: () => ({ theme, ...props }) => {
        const values = getValues({ ...palette, ...theme }, props);

        return values[key][theme.name] || values[key]["default"];
      }
    }),
    {}
  );

/**
 *
 * Usage
 *
 * ```Component.js
 *  import { usePalette } from "Libs/theme";
 *  const Component = props => {
 *    const theme = usePalette(palette => {
 *      icon {
 *        default: palette.primary,
 *        contrast: "#ffffff"
 *      }
 *    });
 *
 *    return <Icon color={theme.icon} />
 *  };
 * ```
 */
export const usePalette = getValues => {
  const theme = useTheme();
  const values = getValues({ ...palette, ...theme }, {});

  return Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value[theme.name] || value["default"]
    }),
    {}
  );
};
