import styled, { css } from "styled-components";

const WIDTH = 24;

export const Layout = styled.button`
  height: ${WIDTH}px;
  width: ${WIDTH}px;
  display: block;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 11;
  position: relative;

  :after {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -8px;
    top: -7px;
    z-index: -1;

    background-color: #4a495e;
    border-radius: 2px;

    opacity: 0;
    transition: 0.3s;

    ${({ isOpen }) =>
      isOpen &&
      css`
        width: 32px;
        height: 32px;
        left: -5px;
        top: -4px;
      `};
  }

  :hover :after {
    opacity: 1;
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 0;
  height: 2px;
  background-color: white;
  width: ${WIDTH}px;
  transition-property: transform, width, height, background-color;
  transition-duration: 0.3s;
  transform-origin: center;

  &:first-child {
    transform: translate3d(0px, 8px, 0px) rotate(0deg);
  }

  &:last-child {
    transform: translate3d(0px, 16px, 0px) rotate(0deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: ${WIDTH / 2}px;
      height: 1px;
      background-color: #98a0ab;

      &:first-child {
        transform: translate3d(${WIDTH / 4}px, 12px, 0px) rotate(45deg);
      }

      &:last-child {
        transform: translate3d(${WIDTH / 4}px, 12px, 0px) rotate(-45deg);
      }
    `};
`;
