import React from "react";
import PropTypes from "prop-types";

import useMediaQuery from "Hooks/useMediaQuery";

import NavigationMenu from "../NavigationMenu";
import FloatBox from "../FloatBox";
import ProjectMoreLinks from "Components/ProjectMoreLinks";

import * as S from "./styles";

const ProjectNavBar = ({
  toggleProjectWizard,
  subscription,
  project,
  organizationId,
  projectId,
  ...props
}) => {
  const onDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        subscription={subscription}
        organizationId={organizationId}
        projectId={projectId}
        {...props}
      />

      {onDesktop && (
        <S.LiveButton
          project={project}
          subscription={subscription}
          organizationId={organizationId}
        />
      )}

      <ProjectMoreLinks
        from="project"
        project={project}
        subscription={subscription}
        toggleProjectWizard={toggleProjectWizard}
        projectId={projectId}
      />
    </FloatBox>
  );
};

ProjectNavBar.propTypes = {
  toggleProjectWizard: PropTypes.func,
  project: PropTypes.object,
  subscription: PropTypes.object,
  organizationId: PropTypes.string,
  projectId: PropTypes.string
};

export default ProjectNavBar;
