import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer, { history } from "Reducers";
import { Iterable } from "immutable";
import { routerMiddleware } from "connected-react-router";

const middlewares = [routerMiddleware(history), thunk];

function configureStoreProd(initialState) {
  const store = createStore(
    rootReducer(),
    initialState,
    applyMiddleware(...middlewares)
  );
  store.asyncReducers = {};
  return store;
}

let configureStore;

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "pre-production"
) {
  configureStore = configureStoreProd;
} else {
  const configureStoreDev = initialState => {
    const isImmutable = require("redux-immutable-state-invariant/dist/isImmutable")
      .default;
    const devMiddlewares = [
      // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
      // TODO: replace Iterable.isIterable by isImmutable() on immutable v4
      require("redux-immutable-state-invariant").default({
        isImmutable: o => isImmutable(o) || Iterable.isIterable(o)
      }),

      // thunk middleware can also accept an extra argument to be passed to each thunk action
      // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
      ...middlewares
    ];

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false }) ||
      compose; // add support for Redux dev tools
    const store = createStore(
      rootReducer(),
      initialState,
      composeEnhancers(applyMiddleware(...devMiddlewares))
    );

    store.asyncReducers = {};

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept("Reducers", () => {
        const nextReducer = require("Reducers").default; // eslint-disable-line global-require
        store.replaceReducer(nextReducer(store.asyncReducer));
      });
    }

    return store;
  };
  configureStore = configureStoreDev;
}

export default configureStore;
