import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import ChevronIcon from "Components/icons/ChevronIcon";
import MenuDropdownWrapper from "Components/MenuDropdownWrapper";

import getUrls from "./getUrls";
import MenuLink from "./MenuLink";
import MobileMenu from "./MobileMenu";

import * as S from "./styles";

const UserMenu = ({
  userInfo,
  accountUrl,
  breadcrumbs,
  organizations,
  organizationId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const $layout = useRef(null);

  let organization = organizations && organizations.get(organizationId, {});

  useEffect(
    () => {
      const handleClickOutside = event => {
        if (!ReactDOM.findDOMNode($layout.current).contains(event.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", handleClickOutside, false);

      return () => document.removeEventListener("click", handleClickOutside);
    },
    [$layout]
  );

  const urls = getUrls(accountUrl, userInfo.username, organization?.name);

  return (
    <>
      <MobileMenu breadcrumbs={breadcrumbs} user={userInfo} urls={urls} />
      <S.Layout ref={$layout}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className={`user-menu-toggle${isOpen ? " open" : ""}${
            userInfo.picture && !userInfo.picture.endsWith("user-default.png")
              ? " picture"
              : ""
          }`}
          aria-label="User menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {userInfo.picture &&
          !userInfo.picture.endsWith("user-default.png") ? (
            <img src={userInfo.picture} alt={userInfo.display_name} />
          ) : (
            userInfo.display_name
          )}
          <ChevronIcon color="#ffffff" />
        </button>
        <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
          <ul>
            {process.env.ENABLE_ORGANIZATION && (
              <S.OrganizationLabel>{organization?.label}</S.OrganizationLabel>
            )}
            {process.env.CUSTOM_VENDOR_CONSOLE_URL &&
              process.env.CUSTOM_VENDOR_CONSOLE_TITLE && (
                <li>
                  <MenuLink
                    label={process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
                    url={process.env.CUSTOM_VENDOR_CONSOLE_URL}
                  />
                </li>
              )}
            {!process.env.ENABLE_ORGANIZATION && (
              <>
                <li>
                  <MenuLink label="Account" url={urls.profileUrl} />
                </li>
                <li>
                  <MenuLink label="Billing" url={urls.billingUrl} />
                </li>
              </>
            )}
            {process.env.ENABLE_ORGANIZATION &&
              organizationId && (
                <>
                  <li>
                    <MenuLink
                      label="Settings"
                      url={urls.organizationSettingsUrl}
                    />
                  </li>
                  <li>
                    <MenuLink
                      label="Billing"
                      url={urls.organizationBillingUrl}
                    />
                  </li>
                  <S.Separator />
                </>
              )}
            {process.env.ENABLE_ORGANIZATION && (
              <li>
                <MenuLink label="My profile" url={urls.profileUrl} />
              </li>
            )}
            <li>
              <MenuLink label="Support" url={urls.supportUrl} />
            </li>
            <li>
              <MenuLink
                label="Documentation"
                target="_blank"
                rel="noopener noreferrer"
                url={urls.docsUrl}
              />
            </li>
            <li>
              <MenuLink label="Logout" url={urls.logoutUrl} />
            </li>
          </ul>
        </MenuDropdownWrapper>
      </S.Layout>
    </>
  );
};

UserMenu.propTypes = {
  userInfo: PropTypes.object,
  organizations: PropTypes.object,
  accountUrl: PropTypes.string,
  organizationId: PropTypes.string,
  breadcrumbs: PropTypes.node
};

export default UserMenu;
