import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import { WingsDialog } from "../EnvironmentListBar/styles";

export const Wrapper = styled.div`
  position: relative;
  .environment-line a {
    position: relative;
    margin: 0 -32px;
    padding: 0 32px;
    line-height: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    box-sizing: inherit;
    &:hover,
    &:focus {
      border-radius: 0;
      outline: none;
    }
    .list-bar-right {
      line-height: 32px;
      width: 76px;
      @media (min-width: 1280px) {
        width: 120px;
      }
    }
  }
  .info-button.website-link {
    top: 12px;
  }
  .environment-line ${WingsDialog} {
    top: 15px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background: ${props => props.theme.envTreeRootIconBg} !important;
          svg path {
            fill: ${props => props.theme.envTreeRootIcon};
          }
        }
      }
    }
    .title {
      ${semiBoldAlias};
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .list-bar-left {
    .icon {
      svg path {
        fill: ${props => props.theme.primary};
      }
      &.inactive {
        svg path {
          fill: ${props => props.theme.envTreeInactiveIcon};
        }
      }
    }
  }
  .inactive {
    .title {
      color: ${props => props.theme.envTreeInactiveText};
    }
  }
  &.has-children {
    .list-bar-left {
      .icon {
        background: ${props => props.theme.primary};
        svg path {
          fill: ${props => props.theme.envTreeActiveIcon};
        }
        &.inactive {
          background: ${props => props.theme.envTreeInactiveIconBg} !important;
          svg path {
            fill: ${props => props.theme.envTreeInactiveIcon};
          }
        }
      }
    }
  }
`;
