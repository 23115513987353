import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";
import { useIntl } from "react-intl";

import { MenuLinkItem } from "../";
import SearchInput from "Components/SearchInput";

import * as S from "./styles";

const Menu = ({
  closeDropDown,
  currentEnvironment,
  id,
  list,
  organizationId,
  tree
}) => {
  const [titleFilter, setTitleFilter] = useState("");
  const intl = useIntl();

  const environmentFilter = env => {
    if (env.status === "inactive") {
      return false;
    }
    if (!env.title) return true;

    return env.title.toUpperCase().includes(titleFilter.toUpperCase());
  };

  if (!list || !list.length) {
    return false;
  }

  let environments = tree;
  if (titleFilter !== "") {
    environments = list.filter(environmentFilter).map(l => {
      return { ...l, children: [] };
    });
  }
  environments = environments.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  const nbElements = environments.reduce((acc, cur) => {
    return acc + cur.children.length;
  }, environments.length);
  const moreThanTen = nbElements > 10;

  return (
    <S.Wrapper role="navigation">
      {titleFilter && (
        <LiveMessage
          message={`environment list results filtered by ${titleFilter}. ${
            environments.length
          } environments found.`}
          aria-live="polite"
        />
      )}
      <SearchInput
        inputColor="#404041"
        iconSize={14}
        placeholder="Search environments"
        onChange={e => setTitleFilter(e.target.value)}
        value={titleFilter}
        className="search"
        id="environment-search"
      />
      <S.Environments
        className={`environments-links`}
        role="menu"
        aaria-label="environments"
      >
        {list.length === 0 ? (
          <S.Empty>
            {intl.formatMessage({
              id: "environmentTree.menu.empty",
              defaultMessage: "No results."
            })}
          </S.Empty>
        ) : (
          <ListWrapper scrollable={moreThanTen}>
            <ol>
              {environments.map(node => {
                return (
                  <MenuLinkItem
                    id={id}
                    node={node}
                    organizationId={organizationId}
                    currentEnvironment={currentEnvironment}
                    key={node.environmentId}
                    searchValue={titleFilter}
                    filter={environmentFilter}
                    closeDropDown={closeDropDown}
                  />
                );
              })}
            </ol>
          </ListWrapper>
        )}
      </S.Environments>
    </S.Wrapper>
  );
};

const ListWrapper = ({ children, scrollable }) =>
  scrollable ? (
    <CustomScroll heightRelativeToParent="332px">{children}</CustomScroll>
  ) : (
    <div className="no-scroll">{children}</div>
  );

Menu.propTypes = {
  list: PropTypes.array,
  tree: PropTypes.array,
  organizationId: PropTypes.string,
  currentEnvironment: PropTypes.string,
  id: PropTypes.string,
  closeDropDown: PropTypes.func
};

ListWrapper.propTypes = {
  children: PropTypes.node,
  scrollable: PropTypes.bool
};

export default Menu;
