import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VendorLogoContainer = styled.div`
  width: ${props => props.size}px;
  margin-top: -2px;
  img {
    width: 100%;
  }
`;

const VendorIcon = props => (
  <VendorLogoContainer size={props.size}>
    <img src={props.path} alt="logo" />
  </VendorLogoContainer>
);

VendorIcon.propTypes = {
  path: PropTypes.string,
  size: PropTypes.number
};

export default VendorIcon;
