const getUrls = (accountUrl, username, organizationId) => {
  let profileUrl = `/-/users/${username}/settings`;
  let billingUrl = `/-/users/${username}/billing`;
  let supportUrl = `/-/users/${username}/tickets`;
  let docsUrl = "https://docs.platform.sh";
  let logoutUrl = "/-/logout";
  let organizationSettingsUrl = `/${organizationId}/-/settings`;
  let organizationBillingUrl = `/${organizationId}/-/billing`;

  if (process.env.ACCOUNT_PAGES_DISABLED) {
    profileUrl = `${accountUrl}/user`;
    billingUrl = `${accountUrl}/user/orders`;
    supportUrl = `${accountUrl}/support`;
  }

  if (process.env.CUSTOM_BILLING_URL) {
    billingUrl = process.env.CUSTOM_BILLING_URL;
  }

  if (process.env.CUSTOM_SUPPORT_URL) {
    supportUrl = process.env.CUSTOM_SUPPORT_URL;
  }

  if (process.env.CUSTOM_DOCS_URL) {
    docsUrl = process.env.CUSTOM_DOCS_URL;
  }

  return {
    profileUrl,
    billingUrl,
    organizationSettingsUrl,
    organizationBillingUrl,
    docsUrl,
    supportUrl,
    logoutUrl
  };
};

export default getUrls;
