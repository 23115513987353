import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import { useIntl } from "react-intl";

import ActionDropdown from "Components/ActionDropdown";
import InfoDialog from "Components/InfoDialog";
import SearchInput from "Components/SearchInput";
import ToggleTree from "Components/ToggleTree";
import CheckboxField from "Components/fields/CheckboxField";
import RadioField from "Components/fields/RadioField";
import Heading6 from "Components/styleguide/Heading6";
import IconFilter from "Icons/IconFilter";

import * as S from "./styles";

const NavBar = ({
  treeLayout,
  setTreeLayout,
  titleFilter,
  setTitleFilter,
  showActive,
  setShowActive,
  showInactive,
  setShowInactive,
  sortType,
  toggleSort
}) => {
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  return (
    <S.Wrapper>
      <SearchInput
        placeholder={intl.formatMessage({
          id: "environmentTree.navbar.search",
          defaultMessage: "Search"
        })}
        className="float"
        onChange={e => setTitleFilter(e.target.value)}
        value={titleFilter}
        id="project-environment-search"
      />
      <ActionDropdown
        id="environments-filter"
        label={intl.formatMessage({
          id: "environmentTree.navbar.filter.label",
          defaultMessage: "Filter"
        })}
        icon={
          <span className="icon">
            <IconFilter
              color={`${
                theme && theme.name === "dark" ? "#c9d0e4" : "#4a495e"
              }`}
            />
          </span>
        }
        iconAfter={true}
        withArrow={false}
        withClose={true}
      >
        <Heading6 className="title">
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.label",
            defaultMessage: "Filter environments"
          })}
        </Heading6>
        <hr />
        <Heading6>
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.show",
            defaultMessage: "Show"
          })}
        </Heading6>
        <CheckboxField
          forId="toggle-inactive"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.inactive",
            defaultMessage: "Inactive"
          })}
          type="checkbox"
          value={showInactive}
          onChange={() => setShowInactive(!showInactive)}
        />
        <CheckboxField
          forId="toggle-active"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.active",
            defaultMessage: "Active"
          })}
          type="checkbox"
          value={showActive}
          onChange={() => setShowActive(!showActive)}
        />
        <hr />
        <Heading6>
          {intl.formatMessage({
            id: "environmentTree.navbar.filter.sort",
            defaultMessage: "Sort by"
          })}{" "}
          {treeLayout && (
            <InfoDialog
              align="center"
              text={intl.formatMessage({
                id: "environmentTree.navbar.filter.sort_available",
                defaultMessage:
                  "Environments can only be sorted in the list view."
              })}
            />
          )}
        </Heading6>
        <RadioField
          forId="sort-alphabetically"
          name="sort"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.alphabetically",
            defaultMessage: "Alphabetically A-Z"
          })}
          value={sortType === "name" && !treeLayout}
          onChange={() => toggleSort("name")}
          disabled={treeLayout}
        />
        <RadioField
          forId="sort-by-created"
          name="sort"
          label={intl.formatMessage({
            id: "environmentTree.navbar.filter.date",
            defaultMessage: "Date created"
          })}
          value={sortType === "created" && !treeLayout}
          onChange={() => toggleSort("created")}
          disabled={treeLayout}
        />
      </ActionDropdown>
      <ToggleTree toggleLayout={setTreeLayout} gridLayout={treeLayout} />
    </S.Wrapper>
  );
};

NavBar.propTypes = {
  treeLayout: PropTypes.bool,
  setTreeLayout: PropTypes.func,
  titleFilter: PropTypes.string,
  setTitleFilter: PropTypes.func,
  showActive: PropTypes.bool,
  setShowActive: PropTypes.func,
  showInactive: PropTypes.bool,
  setShowInactive: PropTypes.func,
  sortType: PropTypes.string,
  toggleSort: PropTypes.func
};

export default NavBar;
