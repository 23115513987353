import { useParams } from "react-router-dom";

const useDecodedParams = () => {
  const params = useParams();
  const entries = Object.entries(params);
  const decodedParams = entries.reduce((params, [key, value]) => {
    params[key] = value ? decodeURIComponent(value) : value;
    return params;
  }, {});

  return decodedParams;
};

export default useDecodedParams;
