import styled, { keyframes } from "styled-components";
import { themeHelpers } from "Libs/theme";

import Button from "UI/Button";

export const LayoutWrapper = styled.span`
  display: inline-block;
  position: relative;
  &.link-style {
    button {
      background: transparent;
      color: ${props => props.theme.links};
      svg > g path {
        fill: ${props => props.theme.links};
      }
      &:hover {
        background: ${props => themeHelpers(props.theme, "buttonColor")};
        color: ${props => props.theme.buttonText};
        svg > g path {
          fill: ${props => props.theme.buttonText};
        }
      }
    }
  }
`;

const popin = keyframes`
  0% {
    opacity: 0;
    height: 10px;
    width: 10px;
  }
  20% {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  80% {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  100% {
    height: 10px;
    width: 10px;
    opacity: 0;
  }
`;

const fadeInOut = keyframes`
  0% {
    background: rgba(22, 100, 255, 0);
  }
  30% {
    background: rgba(22, 100, 255, 1);
  }
  70% {
    background: rgba(22, 100, 255, 1);
  }
  100% {
    background: rgba(22, 100, 255, 0);
  }
`;

export const Layout = styled(Button)`
  color: ${props => props.theme.buttonText};
  background: ${props => themeHelpers(props.theme, "buttonColor")};
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 16px 0 8px;
  border-radius: 2px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 !important;

  @media (min-width: 768px) {
    line-height: 32px;
    height: 32px;
  }

  span {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  svg {
    margin-right: 8px;
    width: 24px;
    height: auto;
    > g path {
      fill: ${props => props.theme.buttonText};
    }
  }
  .copied-message {
    color: ${props => props.theme.links};
  }
  &:hover,
  &:hover .copied-message {
    background-color: ${props => themeHelpers(props.theme, "buttonHover")};
    svg > g > g {
      fill: ${props => props.theme.buttonText};
    }
  }
  &:focus {
    border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
    outline: none;
  }
  &:active {
    box-shadow: none;
    margin: 0 !important;
    background-color: ${props => themeHelpers(props.theme, "buttonHover")};
  }
`;

export const Info = styled.span`
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => themeHelpers(props.theme, "buttonColor")};
  border-radius: 3px;
  color: ${props => props.theme.buttonText};
  font-weight: bold;
  animation: ${fadeInOut} 0.4s linear 1;
  background: rgba(71, 134, 255, 0);

  > span {
    opacity: 0;
    border-radius: 100%;
    display: inline-flex;
    animation: ${popin} 0.4s linear 1;
    box-sizing: border-box;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
